<template>
  <div class="tabs-component flex flex-wrap">
    <div class="w-full">
      <ul class="flex mb-0 list-none flex-wrap pt-3 pb-4 flex-row">
        <li
          v-for="tab in tabs"
          :key="tab.name"
          class="-mb-px mr-2 mt-2 last:mr-0 flex-auto text-center cursor-pointer"
        >
          <a
            class="text-xs font-bold uppercase px-5 py-3 shadow-md rounded block leading-normal"
            :class="{
              'text-white bg-sms-red': selected === tab.name,
              'sms-red bg-white': selected !== tab.name
            }"
            @click="selected = tab.name"
          >
            {{ tab.name }}
          </a>
        </li>
      </ul>
      <div
        class="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-md rounded"
      >
        <div class="px-4 py-5 flex-auto">
          <div class="tab-content tab-space">
            <div
              v-show="selected === tab.name"
              v-for="tab in tabs"
              :key="tab.name"
            >
              <p v-if="!tab.html">
                {{ tab.content }}
              </p>
              <p v-if="tab.html" v-html="tab.content" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "TabsComponent",
  props: {
    tabs: Array
  },
  data: () => {
    return {
      selected: ""
    };
  },
  beforeMount() {
    this.selected = this.tabs[0].name;
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
.tabs-component {
  .sms-red {
    color: #aa222c;
  }
  .bg-sms-red {
    background-color: #aa222c;
  }
  .tab-content {
    text-align: left;
    font-size: 13px;
    color: #747474;
    padding-left: 40px;
    p {
      margin-bottom: 20px;
    }
    h2 {
      color: black;
      font-size: 18px;
      @apply pb-4;
    }
    ol,
    ul {
      margin-top: 13px;
      margin-bottom: 13px;
    }
    ul {
      list-style: disc;
    }
    ol {
      list-style: decimal;
    }
    li {
      margin-left: 20px;
    }
  }
}
</style>
